<template>
  <div class="rights">
    <RightsHero />
    <RightsContent />
    <Footer />
  </div>
</template>

<script>
import RightsHero from "@/components/Rights/Hero.vue";
import RightsContent from "@/components/Rights/Content.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    RightsHero,
    RightsContent,
    Footer,
  },
  jsonld: {
    "@context": "http://schema.org",
    "@type": "WebSite",
    url: "https://www.liberfly.com.br/",
    name: "LiberFly | Quando tenho direito?",
    alternateName:
      "Conheça seus direitos para garantir sua indenização | LiberFly",
    description:
      "A LiberFly é a maior empresa de Soluções de Problemas Aéreos da América Latina e busca a sua indenização sem custos ou burocracia",
    keywords:
      "voo,cancelado,indenização,avião,direito,aviao,atrasado,indenização,avião,indenização,bagagem,extraviada,quebrada,danificada,overbooking,no,show,noshow",
  },
  mounted() {
    const meta = document.querySelector('meta[name="robots"]');
    if (this.$route.meta.robots) {
      meta.setAttribute("content", this.$route.meta.robots);
    }
  },
};
</script>

<style lang="scss" scoped>
.rights {
  background: $blue-primary;
  padding-top: 60px;
}
</style>
