<template>
  <section id="content">
    <div class="movetop">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="text" id="flight_cancel">
              <div class="title">
                Cancelamento de voo
              </div>
              <div class="description">
                A companhia aérea pode, sim, cancelar o seu voo. Porém, deve ser realizado com, no mínino, 30 dias de antecedência da data da viagem, oferecendo ao cliente a opção de reembolso ou realocação em outro voo. Se o cancelamento for realizado após 30 dias, ou em cima da hora, o passageiro tem direito a, além do reembolso ou realocação, indenização por danos morais e materiais.
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="image">
              <img src="@/assets/flightcancel.png" alt="Cancelamento de voo">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="image">
              <img src="@/assets/flightdelay.png" alt="Atraso de voo">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="text" id="flight_delay">
              <div class="title">
                Atraso de voo
              </div>
              <div class="description">
                São vários os motivos que fazem o voo atrasar: condições climáticas, manutenção da aeronave e espera da conexão são alguns exemplos. Se a aeronave chegou ao destino com 4 horas ou mais de atraso, você tem direito a indenização. A companhia aérea deve prestar assistência material enquanto você espera o embarque. Caso a empresa tenha negado assistência material, você também tem direito a compensação financeira.
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="text" id="lost_luggage">
              <div class="title">
                Bagagem extraviada ou danificada
              </div>
              <div class="description">
                Extravio e dano em bagagem são dois dos piores pesadelos dos viajantes. O primeiro passo é fazer o Registro de Irregularidade de Bagagem (RIB) no guichê da companhia aérea em até 7 dias após o embarque/desembarque. Conforme as leis da Anac, sua bagagem deve chegar em até 21 dias (em casos de voos internacionais) ou 30 dias (em casos de voos nacionais). Após esse prazo, é considerado extravio definitivo. Você tem direito a indenização em todos os casos de bagagem extraviada ou danificada.
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="image">
              <img src="@/assets/lostluggage.png" alt="Bagagem extraviada">
            </div>
          </div>
        </div>
      </div>
      <CallToAction />
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="image">
              <img src="@/assets/overbooking.png" alt="Overbooking">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="text">
              <div class="title" id="overbooking">
                Overbooking
              </div>
              <div class="description">
                Overbooking significa voo superlotado, ou seja, é quando a companhia aérea vende mais passagens do que pode oferecer. Também conhecido como preterição de embarque, o overbooking é uma prática comum, porém abusiva e vai contra o Código de Defesa do Consumidor. O overbooking é um pretexto para que a empresa evite voar com assentos vazios, pois conta com a desistência de alguns passageiros. Se você foi barrado de voar, tem direito a realocação ou reembolso, além da assistência material e posterior indenização.
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="text">
              <div class="title" id="no_show">
                No-show
              </div>
              <div class="description">
                O termo no-show significa não comparecimento. Em muitos casos, o não comparecimento no voo de ida implica no cancelamento automático da passagem de volta feito pela companhia aérea. Ou seja, o passageiro que comprou as passagens de ida e volta, mas decidiu ir mais cedo para o destino, tem seu direito retirado mesmo tendo comprado a passagem de volta.
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="image">
              <img src="@/assets/noshow.png" alt="No-show">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CallToAction from "@/components/CallToAction.vue";

export default {
  components: {
    CallToAction,
  },
};
</script>

<style lang="scss" scoped>
#content {
  background: $blue-primary;
  margin-bottom: -80px;

  @media (max-width: 576px) {
    margin-bottom: -1px;
  }

  .movetop {
    position: relative;
    top: -80px;

    @media (max-width: 576px) {
      top: -1px;
    }
  }

  .container {
    padding-top: 99px;
    background: $white;
    border-top: 7px solid $blue-primary;

    @media (max-width: 576px) {
      padding-top: 50px;
    }
  }

  .text {
    padding: 0 40px;
    margin-bottom: 99px;

    @media (max-width: 576px) {
      padding: 0;
      margin-bottom: 50px;
    }

    .title {
      font-weight: $semibold;
      font-size: 24px;
      line-height: 29px;
      color: $blue-primary;
      margin-bottom: 26px;

      @media (max-width: 576px) {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 13px;
      }
    }

    .description {
      color: $black;
      font-weight: $regular;
      font-size: 18px;
      line-height: 30px;

      @media (max-width: 576px) {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  .image {
    padding: 0 40px;

    @media (max-width: 991px) {
      display: none;
    }

    img {
      width: 100%;
    }
  }
}
</style>
